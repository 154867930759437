// src/App.js
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Snowfall from "react-snowfall";
import "./App.css";

const AdventCalendar = () => {
  const tasks = {
    1: {
      text: "Napiš nám do DMs na instagramu „❄️“ a tím se zapojíš do celého adventního eventu. Do odevzdání napiš svůj instagram username.",
      type: "text",
      link: "https://instagram.com/byxbot",
      rewards: "Šance na získání $5 (v náhodném krypto tokenu), získá pouze jeden z připojených.",
    },
    2: { text: "Dej like na připnutý příspěvěk na našem instagramu a napiš do komentáře co by jsi si přál k vánocům, následně odešli screenshot svého komentáře.", type: "image", link: "https://instagram.com/byxbot", rewards: "PDF „Základní tradingové tipy od trading týmu ByX."},
    3: {
      text: "Odpověz na otázku: Co je ByX?",
      type: "text",
      rewards: "Zařazení do slosování o $5 v USDT.",
    },
    4: {
      text: "Najdi tajný symbol na našem webu a pošli nám jeho screenshot.",
      type: "image",
      link: "https://byxbot.com",
      rewards: "Sleva 100 % na druhý měsíc členství ByX.",
    },
    5: {
      text: "Odpověz na tyto otázky o kryptu.",
      type: "quiz",
      questions: [
        { question: "Co je Bitcoin (BTC)?", options: ["Kryptoměna", "Akcie", "Komodita"], answer: "Kryptoměna" },
        { question: "Kdy byl Bitcoin vytvořen?", options: ["2009", "2010", "2015"], answer: "2009" },
        { question: "Co je blockchain?", options: ["Obchodní řetězec", "Komodita", "Technologie"], answer: "Technologie" },
      ],
      rewards: "E-book o cryptu a investování do něho.",
    },
    6: {
      text: "Sdílej náš post na story a označ nás (odešli nám screenshot tohoto stories).",
      type: "image",
      link: "https://instagram.com/byxbot",
      rewards: "Mini průvodce PDF „Základy risk managementu.",
    },
    7: {
      text: "Na základě Ebooku z 5. dne vysvěli jaké fáze marketu máme (jsou čtyři).",
      type: "text",
      rewards: "100% cashback z Ambassador program ByX. (Pozvi kamaráda do ByX a dostaň 100% toho co zaplatil zpět).",
    },
    8: {
      text: "Poděl se o zkušenosti s ByX adventním kalendářem na stories a označ nás. (odešli screenshot stories)",
      type: "image",
      link: "https://instagram.com/byxbot",
      rewards: "Možnost zeptat se nás na cokoliv (na live Q&A 13.12.2024).",
    },
    9: {
      text: "Napiš nám tvoje zkušenosti s tradingem (pokud nějakou máš), pokud ne, odevzdej prázdné.",
      type: "text",
      rewards: "Bonusový přístup k ByX na 7 dní.",
    },
    10: { text: "Napiš co by jsi uvítal v ByX v roce 2025 (jaká koliv funkce nebo nápad)", type: "text", rewards: "Získáš exkluzivní přístup do tajného kanálu ByX."},
    11: {
      text: "Podívej se na krátké video (odkaz nalezneš dnes na našem stories) a napiš nám tajný kód (5 znaků), který jsi ve videu zahlédl.",
      type: "text",
      rewards: "Speciální video shrnutí „Jak se orientovat v trendech rychleji než ostatní.",
    },
    12: {
      text: "Napiš nám aktuální cenu BTC ke dni 12.12.2024 (stačí v tisících).",
      type: "text",
      rewards: "Nápověda pro úkol co příjde později v kalendáři.",
    },
    13: { text: "Sleduj náš krátký live webinář (odkaz získáš na stories na našem instagramu 13.12.2024, konat se bude 17:00. (Pošli nám screenshot jak na to koukáš.) Záznam bude k dispozici na našem instagramu.", type: "text", link: "https://instagram.com/byxbot", rewards: "Krátký video kurz o analýze síly trendu trhu." },
    14: {
      text: "Napiš nám odpověď na otázku získanou pomocí nápovědy ze dne 12.",
      type: "text",
      rewards: "Speciální video „Jak se připravit na nečekané pohyby na trhu“.",
    },
    15: {
      text: "Hádejte: Jsem digitální, nemůžete mě fyzicky držet, ale mohu vám přinést bohatství. Jsem zabezpečený, ale nemám trezor Co jsem?",
      type: "text",
      rewards: "Zapojení do soutěže o $10 v USDT.",
    },
    16: { text: "Dej like na nejnovější příspěvek na našem instagramu. (napiš nám svůj username.", type: "text", rewards: "Přístup k krátkému videu o tom, jak efektivně používat stop-loss.",},
    17: {
      text: "Zúčastni se rychlého quizu o cryptu na našem instagramu (napiš nám svůj instagram username)",
      type: "text",
      link: "https://instagram.com/byxbot",
      rewards: "Náš denní checklist.",
    },
    18: { text: "Vytvoř meme o ByX (jakýkoliv, pomocí stránky https://imgflip.com/memegenerator, nebo jakéhokoliv jiného zdroje)", type: "image", link: "https://imgflip.com/memegenerator", rewards: "Extra bod do losování o hlavní cenu (zvýšení šance o 50%).", },
    19: {
      text: ": Podívej se na naše poslední video (odkaz nalezneš dnes na instagramu) a napiš shrnutí co jsi pochopil.",
      type: "text",
      link: "https://instagram.com/byxbot",
      rewards: "PDF „Strategie pro zhodnocení malého kapitálu v cryptu",
    },
    20: {
      text: "Najdi náš skrytý vánoční kód ve stories. Pošli nám ho.",
      type: "text",
      link: "https://instagram.com/byxbot",
      rewards: "Extra přístup k exkluzivnímu obsahu na našem discordu.",
    },
    21: {
      text: "Vytvoř svoje krátké vánoční přáníčko s crypto tematikou a pošli nám ho.",
      type: "text",
      rewards: "Promo kód na měsíční přístup do byx za 50 %. (obdržíš na email)",
    },
    22: {
      text: ": Spočítej, kolik bys měl dneska, kdyby jsi investoval $1 do Bitcoinu v roce 2022 1.1. Pošli nám to číslo.",
      type: "text",
      rewards: "ByX přístup zdarma na týden. (obdržíš na email)",
    },
    23: {
      text: "Vyhledej na internetu a napiš jeden podvodu v crypto světě, aby se ostatní mohli poučit. Pošli nám ho a my ho budeme sdílet na stories",
      type: "text",
      rewards: "Extra bod do losování o hlavní cenu (zvýšení šance o 50%).",
    },
    24: {
      text: "Napiš nám, co se ti nejvíce líbilo z kalendáře (stačí jedna věc).",
      type: "text",
      link: "https://instagram.com/byxbot",
      rewards: "Jeden z účastníků získá $100 v BTC). Výsledek najdeš na našem instagramu.",
    },
  };

  const [quizAnswers, setQuizAnswers] = useState({});


  const [openedBoxes, setOpenedBoxes] = useState(() => {
    const savedBoxes = Cookies.get("openedBoxes");
    return savedBoxes ? JSON.parse(savedBoxes) : {};
  });
  const [completedTasks, setCompletedTasks] = useState(() => {
    const savedTasks = Cookies.get("completedTasks");
    return savedTasks ? JSON.parse(savedTasks) : {};
  });
  const [popupContent, setPopupContent] = useState(null);
  const [taskInput, setTaskInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [nextDayTimer, setNextDayTimer] = useState("");

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [emailError, setEmailError] = useState("");


  const currentDate = new Date();
  const [simulatedDate, setSimulatedDate] = useState(currentDate);

  const simulatedDay = simulatedDate.getDate();
  const simulatedMonth = simulatedDate.getMonth() + 1;

  const isDecember = simulatedMonth === 12;

  const [email, setEmail] = useState(() => Cookies.get("userEmail") || "");
const [isEmailPopupVisible, setIsEmailPopupVisible] = useState(!Cookies.get("userEmail"));

const handleEmailSubmit = () => {
  if (!isValidEmail(email)) {
    setEmailError("Zadejte platnou e-mailovou adresu.");
    return;
  }
  setEmailError(""); // Vymaže chybovou zprávu
  Cookies.set("userEmail", email, { expires: 365 });
  setIsEmailPopupVisible(false);
};



  useEffect(() => {
    const updateTimers = () => {
      const now = new Date();
      const endOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1,
        0,
        0,
        0
      ).getTime();
      const timeRemaining = Math.max(endOfDay - now.getTime(), 0);
      setNextDayTimer(formatTime(timeRemaining));
    };

    updateTimers();
    const interval = setInterval(updateTimers, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (ms) => {
    if (ms <= 0) return "00:00:00";
    const totalSeconds = Math.floor(ms / 1000);
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
    const seconds = String(totalSeconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleOpenBox = (day) => {
    if (!isDecember || day > simulatedDay) return;

    setPopupContent({
      day,
      task: tasks[day],
    });

    const updatedBoxes = { ...openedBoxes, [day]: true };
    setOpenedBoxes(updatedBoxes);
    Cookies.set("openedBoxes", JSON.stringify(updatedBoxes), { expires: 30 });
  };

  const handleSimulateDateChange = (e) => {
    const newDate = new Date(e.target.value);
    if (!isNaN(newDate.getTime())) {
      setSimulatedDate(newDate);
    }
  };

  const closePopup = () => {
    setPopupContent(null);
    setTaskInput("");
    setFileInput(null);
  };

  const [notification, setNotification] = useState(null);

// Funkce pro zobrazení notifikace
const showNotification = (message) => {
  setNotification(message);
  setTimeout(() => {
    setNotification(null); // Skryje notifikaci po 3 sekundách
  }, 3000);
};

const handleTaskSubmit = async () => {
  if (!popupContent) return;

  const task = popupContent.task;
  const webhookUrl = "https://discord.com/api/webhooks/1312174539249025044/l6qX5vnpahc5PJAdNprmBIvE8k77znV3fVRjqFUew10JdWg2o4WtI9UbhgmNY5ya26qU"; // Nahraďte svou webhook URL

  if (task.type === "quiz") {
    const formattedAnswers = Object.entries(quizAnswers)
      .map(
        ([questionIdx, answer]) =>
          `**Otázka ${parseInt(questionIdx) + 1}:** ${task.questions[questionIdx].question}\n**Odpověď:** ${answer}`
      )
      .join("\n\n");

    const payload = {
      content: null,
      embeds: [
        {
          title: `Úkol pro den ${popupContent.day} byl dokončen!`,
          description: `**Email:** ${email}\n\n**Odpovědi na kvíz:**\n${formattedAnswers}`,
          color: 3447003, // Modrá barva
          footer: {
            text: "ByX Adventní Kalendář",
          },
          timestamp: new Date().toISOString(),
        },
      ],
    };

    try {
      await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      console.log("Odpovědi na kvíz odeslány na Discord.");
      showNotification(`Odpovědi na kvíz pro den ${popupContent.day} byly úspěšně odeslány!`);
    } catch (error) {
      console.error("Chyba při odesílání kvízu na Discord:", error);
      showNotification("Nastala chyba při odesílání odpovědí na kvíz. Zkuste to prosím znovu.");
    }
  } else if (task.type === "image" && fileInput) {
    const formData = new FormData();
    formData.append("file", fileInput);
    formData.append("payload_json", JSON.stringify({
      embeds: [
        {
          title: `Úkol pro den ${popupContent.day} byl dokončen!`,
          description: `**Email:** ${email}\n\nPřiložený obrázek:`,
          color: 3447003, // Modrá barva
          footer: {
            text: "ByX Adventní Kalendář",
          },
          timestamp: new Date().toISOString(),
        },
      ],
    }));

    try {
      await fetch(webhookUrl, {
        method: "POST",
        body: formData,
      });
      console.log("Obrázek odeslán na Discord.");
      showNotification(`Obrázek pro den ${popupContent.day} byl úspěšně odeslán!`);
    } catch (error) {
      console.error("Chyba při odesílání obrázku na Discord:", error);
      showNotification("Nastala chyba při odesílání obrázku. Zkuste to prosím znovu.");
    }
  } else {
    const content = task.type === "text"
      ? `Text úkolu: ${taskInput}`
      : "Úkol byl dokončen.";

    const payload = {
      content: null,
      embeds: [
        {
          title: `Úkol pro den ${popupContent.day} byl dokončen!`,
          description: `**Email:** ${email}\n\n${content}`,
          color: 3447003, // Modrá barva
          footer: {
            text: "ByX Adventní Kalendář",
          },
          timestamp: new Date().toISOString(),
        },
      ],
    };

    try {
      await fetch(webhookUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      console.log("Zpráva odeslána na Discord.");
      showNotification(`Úkol pro den ${popupContent.day} byl úspěšně odeslán!`);
    } catch (error) {
      console.error("Chyba při odesílání zprávy na Discord:", error);
      showNotification("Nastala chyba při odesílání úkolu. Zkuste to prosím znovu.");
    }
  }

  const updatedTasks = { ...completedTasks, [popupContent.day]: true };
  setCompletedTasks(updatedTasks);
  Cookies.set("completedTasks", JSON.stringify(updatedTasks), { expires: 30 });

  closePopup();
};



  

const renderPopupContent = () => {
  if (!popupContent || !popupContent.task) return null;
  const task = popupContent.task;

  return (
    <>
      <h2>{task.text}</h2>
      {task.type === "text" && (
        <textarea
          placeholder="Zadejte svůj úkol zde..."
          value={taskInput}
          onChange={(e) => setTaskInput(e.target.value)}
        />
      )}
      {task.type === "image" && (
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setFileInput(e.target.files[0])}
        />
      )}
      {task.type === "quiz" &&
        task.questions.map((q, idx) => (
          <div key={idx} className="quiz-question">
            <p>{q.question}</p>
            {q.options.map((option, i) => (
              <label key={i}>
                <input
                  type="radio"
                  name={`quiz-${idx}`}
                  value={option}
                  onChange={(e) =>
                    setQuizAnswers((prev) => ({
                      ...prev,
                      [idx]: e.target.value,
                    }))
                  }
                />
                {option}
              </label>
            ))}
          </div>
        ))}
      {task.link && (
        <p>
          Více informací:{" "}
          <a href={task.link} target="_blank" rel="noopener noreferrer">
            {task.link}
          </a>
        </p>
      )}
      <p>
        <strong>Odměna za splnění:</strong> {task.rewards || "Žádná odměna není nastavena."}
      </p>
    </>
  );
};



  const renderBoxes = () => {
    return Array.from({ length: 24 }, (_, i) => {
      const day = i + 1;
      const isOpen = openedBoxes[day];
      const isCompleted = completedTasks[day];
      const isMissed = day < simulatedDay && !isCompleted;
      const isLocked = !isDecember || day > simulatedDay;

      return (
        <div
          key={day}
          className={`box ${
            isCompleted ? "completed" : isMissed ? "missed" : ""
          } ${isLocked ? "locked" : ""}`}
          onClick={() => handleOpenBox(day)}
        >
          <div className="box-content">
            <p>{day}</p>
            {isCompleted && <span className="completed-icon">✔️</span>}
            {isMissed && <span className="missed-message">❌</span>}
            {!isCompleted && isLocked && (
              <>
                <span className="lock-icon">🔒</span>
                {day === simulatedDay + 1 && (
                  <p className="timer">{nextDayTimer || "Výpočet..."}</p>
                )}
              </>
            )}
            {!isCompleted && !isLocked && !isMissed && (
              <button>Otevřít</button>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="calendar">
      <Snowfall
        snowflakeCount={100}
        style={{ position: "fixed", width: "100%", height: "100%" }}
      />
      <h1 className="christmass">ByX Kalendář</h1>
      

      {isEmailPopupVisible && (
  <>
    <div className="email-popup22">
      <h2>Zadejte svůj email</h2>
      <input
        type="email"
        placeholder="Váš email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {emailError && <p className="error-message">{emailError}</p>}
      <button onClick={handleEmailSubmit}>Potvrdit</button>
    </div>
    <div className="popup-overlay22"></div>
  </>
)}



      {notification && (
  <div className="notification22">
    {notification}
  </div>
)}


      <div className="grid">{renderBoxes()}</div>
      {popupContent && (
        <>
          <div className="popup22">
          <p className="timerin">Zbývá do uzavření: {nextDayTimer || "Výpočet..."}</p>
            {renderPopupContent()}
            <div className="popup-actions22">
              <button onClick={handleTaskSubmit}>Odeslat</button>
              <button onClick={closePopup}>Zavřít</button>
            </div>
          </div>
          <div className="popup-overlay22" onClick={closePopup}></div>
        </>
      )}
    </div>
  );
};

export default AdventCalendar;
